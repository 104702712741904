<template>
	<v-sheet>
		<v-card flat v-for="(item, i) in items" :key="i" v-bind:id="item.id">
			<v-card-title>
				{{ item.title }}
			</v-card-title>
			<v-card-text>
				<div v-html="item.html"></div>
			</v-card-text>
		</v-card>
		<slot name="append"></slot>
	</v-sheet>
</template>

<style lang="less"></style>
<script>
export default {
	name: "ContentList",
	props: {
		value: { type: Array, default: () => [] },
	},
	data: () => {
		return {
			active: 1,
		};
	},
	computed: {
		items() {
			const self = this;
			return self.value
				.filter((id) => self.$store.state.content.data[id])
				.map((id) => self.$store.state.content.data[id]);
		},
	},
	created() {
		this.$store.dispatch("content/fetchByIds", this.value);
	},
};
</script>
